<template>
  <div>

    <v-container fluid style="width:90%; margin-top:40px;background-color:#f5f5f5;">

<v-stepper non-linear v-model="e1" style="background-color:#efefef;">
      <v-stepper-header>
        <v-stepper-step step="1">
          Cadastro
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Endereço
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Dependentes
        </v-stepper-step>
        
         <v-divider></v-divider>

        <v-stepper-step step="4">
          Conclusão
        </v-stepper-step>
      
      </v-stepper-header>
    </v-stepper>

      <br/>
    <v-container fill-height fluid>
      <v-row align="center"
          justify="left">
            <v-btn @click="voltar" color="warning"> 
                 <v-icon style="margin-right:8px;" right dark>mdi-arrow-left</v-icon>   Voltar 
      </v-btn>
      </v-row>
    </v-container>  
      
           
    <v-banner style="margin-top:20px;">
        <h3> Insira os Dependentes e Agregados </h3>
      </v-banner>


    <v-form ref="form" style="margin-top:20px">
      <form @submit.prevent="salvar">

    <v-row align="center">

    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nome Completo" :rules="[v => !!v || 'Campo Obrigatório']"  v-model="dependente.nome"  outlined></v-text-field>    
    </v-col>
    <v-col class="d-flex" style="height:60px"  cols="12" sm="6">
      <v-text-field dense label="CPF"  type="text" v-model="dependente.cpf"   v-mask="'###.###.###-##'"  outlined></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nascimento" :rules="[v => !!v || 'Campo Obrigatório']"  v-mask="'##/##/####'" v-model="dependente.nascimento" outlined></v-text-field>    
    </v-col>

     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select v-model="dependente.sexo"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="sexos" dense label="Sexo" outlined></v-select>
    </v-col> 

     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select v-model="dependente.tipo"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="tipo" dense label="Tipo" outlined></v-select>
    </v-col> 

    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select v-model="dependente.parentesco"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="parentescos" dense label="Parentesco" outlined></v-select>
    </v-col> 


    <v-col class="d-flex" style="height:60px" cols="12" sm="12">
               <v-tooltip bottom>    
                 <template v-slot:activator="{ on, attrs }">
                  <v-chip outlined color="primary" v-bind="attrs"
                    v-on="on" >{{plano_selecionado.qtd_dep}} Dependentes</v-chip>
               
                 </template>
                       <span>Total de Dependentes que o plano escolhido permite.</span>
               </v-tooltip>
              <v-tooltip bottom>    
                <template v-slot:activator="{ on, attrs }">
                <v-chip outlined color="primary" v-bind="attrs" v-on="on" >{{plano_selecionado.qtd_agr}} Agregados</v-chip>
                </template>
                <span>Total de Agregados que o plano escolhido permite.</span>
               </v-tooltip>
    </v-col>

</v-row>


  <v-banner style="margin-top:20px;" single-line>
    <template v-slot:actions>
      <v-btn
      color="success"
      class="mr-4"
      @click="adicionar">
      Adicionar 
        <v-icon right dark>mdi-account-plus</v-icon>
      </v-btn>
    </template>
  </v-banner>
  <br/>
<v-snackbar
      v-model="snackbar"
    >
      {{ text_snack }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          FECHAR
        </v-btn>
      </template>
    </v-snackbar>


  <v-simple-table style="background-color:#f5f5f5;"
    fixed-header
    height="300px"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" style="width: 45%;">Nome</th>
          <th class="text-left" style="width: 45%;">Tipo</th>
          <th class="text-left"></th>


        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in listadependentes"
          :key="item.nome"
        >
          <td>{{ item.nome }}</td>
          <td>{{ item.tipo }}</td>
          <td> 
            
             <v-btn
              class="ma-2"
              outlined @click="remover(item)"
              color="red"
            >
              Excluir
            </v-btn>


            </td>

        </tr>

       
        

      </tbody>
          <br/>
                    <v-chip outlined color="primary" >Valor Adicional de Agregado: R$ {{valor_total_agregado}}</v-chip>


  <br/>
    </template>
  </v-simple-table>

  <v-banner style="margin-top:20px;" single-line>
    <template v-slot:actions>
      <v-btn color="primary" class="mr-4" @click="prosseguir" :loading="showload" > Prosseguir 
          <v-icon right dark>mdi-arrow-right</v-icon>
      </v-btn>
    </template>
  </v-banner>

       </form>
    </v-form>
    </v-container>
    <br/>
  </div> 
</template>

<script>

import Contrato from '../services/contrato'
import Dependente from '../services/dependentes'

import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import ValidarCPF from '../services/cpf';
import Auxiliar from '../services/auxiliar';
import Cabecalho from '../views/itensPage/Cabecalho';



  export default {
  components: { Cabecalho },
  
 


  created() {

    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }

    if(this.etapa2 == false){
      this.$router.push('/endereco')
    }



    if(this.listadependentes.length>0){
     for(let i = 0; i < this.listadependentes.length; i = i + 1 ) {

            if(this.listadependentes[i].tipo == 'Agregado'){
                  this.qtd_agre_aux = this.qtd_agre_aux + 1
            }else{
                  this.qtd_dep_aux = this.qtd_dep_aux + 1
            }


        }
    }

 },

  computed:{
    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado', 'valor_total_agregado' , 'listadependentes', 'etapa2'
        ]),
      
      /*
    save_contrato:{
          set(v){
              this.$store.commit('setIdContrato', v)
          },
          get(){
             return this.$store.state.contrato;

          }
      },
*/

      isAuthenticated() {
        return this.$store.getters.isAuthenticated
      },

  },


    data: () => ({ 
      
        showload: false,
        snackbar: false,
        text_snack: '',
        e1 : 3,
        qtd_dep_aux: 0,
        qtd_agre_aux: 0,
        disabled: 1,
        dependente: {
          nome: '',
          nascimento: '',
          cpf: '',
          sexo: '',
          tipo: '',
          custo: '',
          parentesco: '',
          id_contrato: '',
          tiket: false,
          percentual: 0,
          ticket: false
        },
        //dependentes:[],
        enderecocep: {},
        contrato:{
        nome: '',
        cpf: '',
        sexo: '',
        nacionalidade: '',
        estadocivil: '',
        dataCarencia: '',
        vencimento: '',
        dataCarencia: '',
        condicao: '',
        qtdParcelas: '',
        id_plano: '',
        descricao_plano: '',
        valorContrato: '',
        parcela: '',
        tipo: '',
        nascimento: '',
        rg: '',
        uf_expedidor: '',
        expedidor: '',
        mae: '',
        religiao: '',
        profissao: '',
        pai: '',
        cep: '',
        logradouro: '',
        complemento: '',
        endereco: '',
        n_res: '',
        bairro: '',
        cidade: '',
        uf_end: '',
        pais: '',
        cep_entrega: '',
        repetir_endereco: true,
        complemento_entrega: '',
        endereco_entrega: '',
        n_res_entrega: '',
        bairro_entrega: '',
        cidade_entrega: '',
        uf_end_entrega: '',
        pais_entrega: '',
        telefone_fixo: '',
        celular: '',
        email: '',
        celular2: '',
        id: 0,
      },

 rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
  
    select: null,

    estadocivil: [
        'Casado',
        'Soleiro',
        'Divorciado',
        'Viúvo(a)',
      ], 

       parentescos: [
        'CONJUGE OU COMPANHEIRO(A)',
        'PAI',
        'MÃE',
        'SOGRO(A)',
        'FILHO(A)',
        'NETO(A)',
        'IRMÃO',
        'AVÓ(Ô)',
        'PRIMO(A)',
        'TIO(A)',
        'SOBRINHO(A)',
        'NORA',
        'GENRO',
        'CUNHADO(A)',
        'OUTROS',
        'NENHUM',


      ], 


      sexos: [
        'Masculino',
        'Feminino',
      ], 
       tipo: [
        'Dependente',
        'Agregado',
      ], 
    


    }),

  

  methods:{
      voltar(){
           this.$router.push('endereco')
      },
      async prosseguir(){
        this.showload = true;
        Contrato.salvar(this.contrato_selecionado).then(response => {
        this.$store.commit('setIdContrato', response.data.id)
          //alert(response+'salvo com sucesso!' + response.data.id)

          if(this.listadependentes.length>0){
              Dependente.salvar(this.listadependentes, this.contrato_selecionado.id).then(response => {
                this.$store.commit('setListaDependente', response.data)
              }).catch(e =>{

              }).finally(() => {
                this.showload = false;
                this.$store.commit('setValorEtapa3', true)

                if(this.contrato_selecionado.id != 0){
                this.$router.push('contratual')
                }
            });

            }else{
              if(this.contrato_selecionado.id != 0){
                 this.$store.commit('setValorEtapa3', true)

                this.$router.push('contratual')
              }
            }

          //this.lista()
        }).catch(e =>{
           this.showload = false;
        }).finally(() => {
          // this.showload = false;
          //this.$router.push('contratual')
      });


      
        
       //this.salvar();
       // this.$router.push('contratual')
       
      },
  

  
      liberarDA(qtd, tipo){

        if(tipo == 'Agregado'){
              if(qtd >= this.plano_selecionado.qtd_agr){
                return false
              }else return true

        }else {
              if(qtd >= this.plano_selecionado.qtd_dep){
               return false
              }else return true

        }
      },
       remover(item){
            this.$store.commit('removerDependente', item.nome)

            if(item.tipo == 'Agregado'){
              this.qtd_agre_aux = this.qtd_agre_aux - 1
            }else{
              this.qtd_dep_aux = this.qtd_dep_aux - 1
            }
            if(this.qtd_dep_aux<0){
                qtd_dep_aux = 0
            }
            if(this.qtd_agre_aux<0){
              qtd_agre_aux = 0
            }

            var total = this.valor_total_agregado - item.custo;


            this.$store.commit('setValorTotalDependente', total)
            
            this.text_snack = "Removido com sucesso!";   
            this.snackbar = true;

       },

      adicionar(){
        this.text_snack = '';

          if(this.$refs.form.validate()){
            var liberado = false;
            if(this.dependente.tipo == 'Agregado'){

                  if(this.liberarDA(this.qtd_agre_aux, 'Agregado' )){
                      liberado = true; 
                  } else{    
                    this.text_snack = "Limite de Agregado é " + this.plano_selecionado.qtd_agr;   
                    }  
            }else

            if(this.dependente.tipo == 'Dependente'){
                  if(this.liberarDA(this.qtd_dep_aux, 'Dependente' )){
                      liberado = true; 
                  }else{
                      this.text_snack = "Limite de Dependente é " + this.plano_selecionado.qtd_dep;   
                  }   
            }
        
       // var teste = ValidarCPF(this.dependente.cpf);
        if(this.dependente.cpf != null){
          if(this.dependente.cpf != ""){
            if(!ValidarCPF(this.dependente.cpf)){
                this.text_snack = "CPF não é valido";   
                liberado = false;
            }
          }
        }

        if(liberado){
            var idade = Auxiliar(this.dependente.nascimento);
            
            var custo_localizado = 0;
            
            if(this.dependente.tipo == 'Agregado'){
                 if(idade>65){
                    custo_localizado = this.plano_selecionado.custo_maior_65_anos;
                }else{
                    custo_localizado = this.plano_selecionado.custo_ate_65_anos;

                }
            }
           
            var total = this.valor_total_agregado;
            this.dependente.custo = custo_localizado;

            this.listadependentes.push(this.dependente);
            this.$store.commit('setValorTotalDependente', total+ custo_localizado)
            this.$store.commit('setDependente', this.dependente)

             if(this.dependente.tipo == 'Agregado'){
                  this.qtd_agre_aux = this.qtd_agre_aux + 1
            }else{
                  this.qtd_dep_aux = this.qtd_dep_aux + 1
            }
                this.clear();
          }else{
            this.snackbar = true;
          
          }

        }
      } , 
      
      clear(){
        this.dependente = {};
        this.$refs.form.reset()
     },

          

    

          
  }
  }

</script>
