import axios from 'axios'
//const url =  'http://10.10.1.32:8080/api/' //'http://localhost:8080/api/'
const url = 'https://api.gpdf.com.br/apiprevida/api/'
//const url = 'https://api.gpdf.com.br/apiprevidateste/api/'

export const http = axios.create({
     baseURL: url, 
})


export default {
     url,
}