<template>
  <div>

    <v-container fluid style="width:90%; margin-top:40px;background-color:#f5f5f5;">

<v-stepper non-linear v-model="e1" style="background-color:#efefef;">
      <v-stepper-header>
        <v-stepper-step step="1">
          Cadastro
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Endereço
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Dependentes
        </v-stepper-step>

           <v-divider></v-divider>

        <v-stepper-step step="4">
          Conclusão
        </v-stepper-step>


      </v-stepper-header>
    
    </v-stepper>
    <br/>
    <v-container fill-height fluid>
      <v-row align="center"
          justify="left">
            <v-btn @click="voltar" color="warning"> 
                 <v-icon style="margin-right:8px;" right dark>mdi-arrow-left</v-icon>   Voltar 
      </v-btn>
      </v-row>
    </v-container>  
      
           
    <v-banner style="margin-top:20px;"
        single-line>
        <h3> Preencha seu endereço </h3>
      </v-banner>



    <v-form ref="form" style="margin-top:20px">
      <form @submit.prevent="salvar">
    <v-row align="center">
     
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Cep" :rules="[v => !!v || 'Campo Obrigatório']" outlined :loading="showload"
        v-model="contrato.cep" v-on:change="searchCep"  v-mask="'#####-###'"></v-text-field> 
      
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Logradouro" :rules="[v => !!v || 'Campo Obrigatório']" :disabled="disabled == 1" outlined v-model="contrato.logradouro"></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Complemento" outlined v-model="contrato.complemento"></v-text-field>    
    </v-col>

<v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nº" :rules="[v => !!v || 'Campo Obrigatório']" outlined v-model="contrato.n_res"></v-text-field>    
    </v-col>
    
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Bairro" :disabled="disabled == 1" outlined v-model="contrato.bairro"></v-text-field>    
    </v-col>

     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Cidade" :disabled="disabled == 1" outlined v-model="contrato.cidade"></v-text-field>    
    </v-col>

 <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="UF" :disabled="disabled == 1" outlined v-model="contrato.uf_end"></v-text-field>    
    </v-col>

<v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Pais" outlined v-model="contrato.pais"></v-text-field>    
    </v-col>

<v-col class="d-flex" style="height:60px" cols="12" sm="12">

<v-container
    class="px-0"
    fluid
  >

  </v-container>
</v-col>
</v-row>

    
 <v-banner style="margin-top:20px;">
        <h3> Endereço de cobrança (entrega de carnê)</h3>
  </v-banner>

 <v-container fill-height fluid>
      <v-row align="center"
          justify="left">
            <v-checkbox
        v-model="contrato.repetir_endereco"
                label="Desejo usar o mesmo endereço de cadastro para endereço de cobrança (Entrega de Carnê)"
                color="blue"
                hide-details>
            </v-checkbox>
      </v-row>
  </v-container> 
      

 <v-row align="center" style="margin-top:20px">

     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Cep" outlined :loading="showload_e" v-model="contrato.cep_entrega"  :disabled="contrato.repetir_endereco" 
 v-on:change="searchCepEntrega"  v-mask="'#####-###'"></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Logradouro" :disabled="disabled == 1" outlined v-model="contrato.endereco_entrega"></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Complemento" :disabled="contrato.repetir_endereco"  outlined v-model="contrato.complemento_entrega"></v-text-field>    
    </v-col>

  <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nº" outlined v-model="contrato.n_res_entrega" :disabled="contrato.repetir_endereco" ></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Bairro" :disabled="disabled == 1" outlined v-model="contrato.bairro_entrega"></v-text-field>    
    </v-col>

     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Cidade" :disabled="disabled == 1" outlined v-model="contrato.cidade_entrega"></v-text-field>    
    </v-col>

 <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="UF" :disabled="disabled == 1" outlined v-model="contrato.uf_end_entrega"></v-text-field>    
    </v-col>

<v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Pais" :disabled="contrato.repetir_endereco"  outlined v-model="contrato.pais_entrega" ></v-text-field>    
    </v-col>


</v-row>


   <br/>
   
   <v-container fill-height fluid>
      <v-row align="center"
          justify="end">
          <v-btn
              @click="clear"
                color="primary"
                text
              >
                Limpar
              </v-btn>
          <v-btn color="primary" class="mr-4" @click="prosseguir"> Prosseguir 
              <v-icon right dark>mdi-arrow-right</v-icon>
          </v-btn>
      </v-row>
    </v-container>  

      <br/>
  

       </form>
    </v-form>

    </v-container>
    <br/>

  </div> 

</template>

<script>

import Contrato from '../services/contrato'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Cabecalho from '../views/itensPage/Cabecalho';



  export default {
  components: {Cabecalho },

 
  
  computed:{

    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado', 'etapa1'
        ]),


  },

    created() {
    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }

     if(this.etapa1 == false){
      this.$router.push('/cadastro')
    }


           this.contrato = this.contrato_selecionado

    },



    data: () => ({ 
        showload_e: false,
        showload: false,
        e1 : 2,
        disabled: 1,
        enderecocep: {},
        contrato:{
        cep: '',
        logradouro: '',
        complemento: '',
        endereco: '',
        n_res: '',
        bairro: '',
        cidade: '',
        uf_end: '',
        pais: '',
        cep_entrega: '',
        repetir_endereco: true,
        complemento_entrega: '',
        endereco_entrega: '',
        n_res_entrega: '',
        bairro_entrega: '',
        cidade_entrega: '',
        uf_end_entrega: '',
        pais_entrega: '',
        telefone_fixo: '',
        celular: '',
        email: '',
        celular2: '',
      },


    rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    rules_email: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
    select: null


    }),

  

  methods:{
     
       voltar(){
           this.$router.push('cadastro')
      },
 
      clear () {
              
        this.$refs.form.reset();
        this.repetir_endereco = true;

      },
    
     prosseguir(){
       if(this.$refs.form.validate()){
        this.contrato.nome = this.contrato_selecionado.nome,
        this.contrato.cpf = this.contrato_selecionado.cpf,
        this.contrato.sexo = this.contrato_selecionado.sexo,
        this.contrato.nacionalidade = this.contrato_selecionado.nacionalidade,
        this.contrato.estadocivil = this.contrato_selecionado.estadocivil,
        this.contrato.dataCarencia = this.contrato_selecionado.dataCarencia,
        this.contrato.vencimento = this.contrato_selecionado.vencimento,
        this.contrato.condicao = this.contrato_selecionado.condicao,
        this.contrato.qtdParcelas = this.contrato_selecionado.qtdParcelas,
        this.contrato.id_plano = this.contrato_selecionado.id_plano,
        this.contrato.descricao_plano = this.contrato_selecionado.descricao_plano,

        this.contrato.valorContrato = this.contrato_selecionado.valorContrato,
        this.contrato.parcela = this.contrato_selecionado.parcela,
        this.contrato.tipo = this.contrato_selecionado.tipo,
        this.contrato.nascimento = this.contrato_selecionado.nascimento,
        this.contrato.rg = this.contrato_selecionado.rg,
        this.contrato.uf_expedidor = this.contrato_selecionado.uf_expedidor,
        this.contrato.expedidor = this.contrato_selecionado.expedidor,
        this.contrato.mae = this.contrato_selecionado.mae,
        this.contrato.religiao = this.contrato_selecionado.religiao,
        this.contrato.profissao = this.contrato_selecionado.profissao,
        this.contrato.pai = this.contrato_selecionado.pai,
        this.contrato.telefone_fixo = this.contrato_selecionado.telefone_fixo,
        this.contrato.celular = this.contrato_selecionado.celular,
        this.contrato.email = this.contrato_selecionado.email,
        this.contrato.celular2 = this.contrato_selecionado.celular2

       if(this.contrato.repetir_endereco){
          this.contrato.cep_entrega = this.contrato_selecionado.cep
          this.contrato.endereco_entrega = this.contrato.logradouro
          this.contrato.complemento_entrega = this.contrato.complemento
          this.contrato.bairro_entrega = this.contrato.bairro
          this.contrato.cidade_entrega = this.contrato.cidade
          this.contrato.uf_end_entrega = this.contrato.uf_end
        }

      this.contrato.id_plano = this.plano_selecionado.id;
      this.contrato.descricao_plano = this.plano_selecionado.descricao;

        //this.$store.commit('setIdPlanoContrato', this.plano_selecionado.id)
        this.$store.commit('setContrato', this.contrato)
        this.$router.push('dependente')
        this.$store.commit('setValorEtapa2', true)

       }
      },


          
     async searchCep() {
     
     this.showload = true;                   
      
    const CEPSemFormatacao = this.contrato.cep.replace('-', '') 

  	if(CEPSemFormatacao.length == 8) {
     
       axios.get(`https://viacep.com.br/ws/${ CEPSemFormatacao }/json/`)
				.then( response => {
        //this.enderecocep = response.data 
        this.contrato.cidade = response.data.localidade;
        this.contrato.bairro = response.data.bairro;
        this.contrato.logradouro = response.data.logradouro;
        this.contrato.uf_end = response.data.uf;
        })
        .catch( error => console.log(error) ).finally()
       
        setTimeout(() => this.showload = false, 1500);
      }
    },

     async searchCepEntrega() {
     
     this.showload_e = true;                   
    const CEPSemFormatacao = this.contrato.cep_entrega.replace('-', '') 

  	if(CEPSemFormatacao.length == 8) {
      
       axios.get(`https://viacep.com.br/ws/${ CEPSemFormatacao }/json/`)
				.then( response => {
        //this.enderecocep = response.data 
        this.contrato.cidade_entrega = response.data.localidade;
        this.contrato.bairro_entrega = response.data.bairro;
        this.contrato.endereco_entrega = response.data.logradouro;
        this.contrato.uf_end_entrega = response.data.uf;
        })
        .catch( error => this.showload_e = false).finally()
       
        setTimeout(() => this.showload_e = false, 1500);

      }
      


    }
  }
  }

</script>
