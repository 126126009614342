<template>
  <div>
    <v-container fluid style="width:90%; margin-top:40px;background-color:#f5f5f5;">


<v-stepper non-linear v-model="e1" style="background-color:#efefef;">
      <v-stepper-header>
        <v-stepper-step step="1">
          Cadastro
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Endereço
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Dependentes
        </v-stepper-step>

           <v-divider></v-divider>

        <v-stepper-step step="4">
          Conclusão
        </v-stepper-step>


      </v-stepper-header>
    </v-stepper>

  <v-banner style="margin-top:20px;"
        single-line>
        <h3> Dados de Cadastro </h3>
      </v-banner>



    <v-form ref="form" style="margin-top:20px">
      <form @submit.prevent="salvar">
    <v-row align="center">
    <v-col class="d-flex" style="height:60px" cols="12" sm="12">
      <v-text-field dense label="Nome Completo*" :rules="[v => !!v || 'Campo Obrigatório']"  v-model="contrato.nome"  outlined></v-text-field>    
    </v-col>
    <v-col class="d-flex" style="height:60px"  cols="12" sm="6">
      <v-text-field dense label="CPF*" type="text" :rules="[v => !!v || 'Campo Obrigatório']"  v-on:change="valida_cpf"  v-model="contrato.cpf"   v-mask="'###.###.###-##'"  outlined></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nascimento*" :rules="[v => !!v || 'Campo Obrigatório']"  v-on:change="valida_idade"  v-model="contrato.nascimento" v-mask="'##/##/####'" outlined></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="RG" outlined v-model="contrato.rg"></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Expedidor" outlined v-model="contrato.expedidor"></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="UF - Expedidor" outlined v-model="contrato.uf_expedidor"></v-text-field>    
    </v-col>
   
    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select  v-model="contrato.estadocivil"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="estadocivil" dense label="Estado Civil*" outlined></v-select>
    </v-col> 
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select v-model="contrato.sexo"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="sexos" dense label="Sexo*" outlined></v-select>
    </v-col> 
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nacionalidade" outlined v-model="contrato.nacionalidade" ></v-text-field>    
    </v-col>
    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Profissão" outlined v-model="contrato.profissao" ></v-text-field>    
    </v-col>
     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-select v-model="contrato.religiao"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="religioes" dense label="Religião*" outlined></v-select>
    </v-col> 
    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nome da Mãe" v-model="contrato.mae" outlined></v-text-field>    
    </v-col>

    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Nome do Pai" outlined v-model="contrato.pai"></v-text-field>    
    </v-col>


  <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Telefone Fixo" v-model="contrato.telefone_fixo" outlined v-mask="'(##) ####-####'" ></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Celular*" :rules="[v => !!v || 'Campo Obrigatório']"  outlined v-model="contrato.celular" v-mask="'(##) #####-####'"></v-text-field>    
    </v-col>


     <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Celular" outlined v-model="contrato.celular2" v-mask="'(##) #####-####'"></v-text-field>    
    </v-col>
    

    <v-col class="d-flex" style="height:60px" cols="12" sm="6">
      <v-text-field dense label="Email" outlined v-model="contrato.email" :rules="rules_email"></v-text-field>    
    </v-col>


</v-row>
    <br/>
   
   <v-container fill-height fluid>
      <v-row align="center"
          justify="end">
             <v-btn @click="clear" color="primary" text> Limpar</v-btn>

            <v-btn color="primary" class="mr-4" @click="prosseguir"> Prosseguir 
                <v-icon right dark>mdi-arrow-right</v-icon>
            </v-btn>
      </v-row>
    </v-container>  

      <br/>


       </form>
      
    </v-form>

    </v-container>
    <br/>
    
  </div> 
 

  
</template>

<script>
import Contrato from '../services/contrato'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Cabecalho from '../views/itensPage/Cabecalho';
import Auxiliar from '../services/auxiliar';
import ValidarCPF from '../services/cpf';


export default {
components: { Cabecalho },


 created() {
    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }
     this.contrato = this.contrato_selecionado
     // this.contrato = this.contrato_selecionado
 },

computed:{

...mapGetters([
            'plano_selecionado', 'contrato_selecionado' ,
        ]),

    
  },
    data: () => ({ 
        e1 : 1,
        disabled: 1,
        dialog_msg: false,
        enderecocep: {},
        msg: '',
        contrato:{
          nome: '',
          cpf: '',
          sexo: '',
          nacionalidade: '',
          estadocivil: '',
          dataCarencia: '',
          vencimento: '',
          dataCarencia: '',
          condicao: '',
          qtdParcelas: '',
          id_plano: '',
          valorContrato: '',
          parcela: '',
          tipo: '',
          nascimento: '',
          rg: '',
          uf_expedidor: '',
          expedidor: '',
          mae: '',
          religiao: '',
          profissao: '',
          pai: '',
          cep: '',
          logradouro: '',
          complemento: '',
          endereco: '',
          n_res: '',
          bairro: '',
          cidade: '',
          uf_end: '',
          pais: '',
        cep_entrega: '',
        repetir_endereco: true,
          complemento_entrega: '',
          endereco_entrega: '',
          n_res_entrega: '',
          bairro_entrega: '',
          cidade_entrega: '',
          uf_end_entrega: '',
          pais_entrega: '',
          telefone_fixo: '',
          celular: '',
          email: '',
          celular2: '',
        },



    rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    rules_email: [
        value => {
          if(value != ''){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
          }
          
        },
      ],
    select: null,

    estadocivil: [
        'Casado',
        'Solteiro',
        'Divorciado',
        'Viúvo(a)',
      ], 
      sexos: [
        'Masculino',
        'Feminino',
      ], 
      
      religioes: [
        'Católico',
        'Evangélico',
        'Testemunha de Jeová',
        'Espírita',
        'Budista',
        'Candomblé',
        'Judeu',
        'Protestante',
        'Ateu',
        'Não Definido',
        'Outra',


      ], 


    }),

  

  methods:{
     
  
      clear () {
      this.$refs.form.reset()
      /*
        this.contrato.nome = '',
        this.contrato.cpf = '',
        this.contrato.sexo = '',
        this.contrato.nacionalidade= '',
        this.contrato.estado_civil= '',
        this.contrato.dataCarencia= '',
        this.contrato.vencimento= '',
        this.contrato.dataCarencia= '',
        this.contrato.condicao= '',
        this.contrato.qtdParcelas= '',
        this.contrato.id_plano= '',
        this.contrato.valorContrato= '',
        this.contrato.parcela= '',
        this.contrato.tipo= '',
        this.contrato.nascimento= '',
        this.contrato.rg= '',
        this.contrato.uf_expedidor= '',
        this.contrato.expedidor= '',
        this.contrato.mae= '',
        this.contrato.religiao= '',
        this.contrato.profissao= '',
        this.contrato.pai= '',
        this.contrato.telefone_fixo= '',
        this.contrato.celular= '',
        this.contrato.email= '',
        this.contrato.celular2 = ''
*/
      },

      prosseguir(){
       
          if(this.$refs.form.validate()){
            this.$store.commit('setContrato', this.contrato)
            this.$store.commit('setValorEtapa1', true)

            this.$router.push('endereco')

            
          }
          
      },

    
      valida_idade(){

               var idade = Auxiliar(this.contrato.nascimento);
               if(idade>65){
                  this.msg = 'Titular não pode ter Idade Maior que 65 anos'
                  this.contrato.nascimento = '';
                  this.dialog_msg = true;
               }
               if(idade < 18){
                  this.msg = 'Titular não pode ter Idade menor que 18 anos'
                  this.contrato.nascimento = '';
                  this.dialog_msg = true;

               }


      },

      valida_cpf(){

                if(!ValidarCPF(this.contrato.cpf)){
                    this.msg = 'CPF ' + this.contrato.cpf+ ' informado não é valido!';
                    this.contrato.cpf = '';
                    this.dialog_msg = true;

                    //this.text_snack = "CPF não é valido";   
                }
      },
          
    searchCep () {
                        

  const CEPSemFormatacao = this.contrato.cep.replace('-', '') 

  	if(CEPSemFormatacao.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ CEPSemFormatacao }/json/`)
				.then( response => {
        //this.enderecocep = response.data 
        this.contrato.cidade = response.data.localidade;
        this.contrato.bairro = response.data.bairro;
        this.contrato.logradouro = response.data.logradouro;
        this.contrato.uf_end = response.data.uf;

        })
        .catch( error => console.log(error) )
        
       



      }
      


    }
  }
  }

</script>

