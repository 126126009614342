import { http } from './config'
const user = JSON.parse(localStorage.getItem('user'))


if (user && user.accessToken) {

    const headers = {  
    "Content-Type": "multipart/form-data",
    'Authorization': 'Bearer ' + user.accessToken
    }

}



class uploadFilesService {

  upload(id, file, onUploadProgress) {

    let formData = new FormData();
    formData.append("foto", file);
    formData.append("pasta", id);

    //return http.post('contrato', contrato , { headers: authHeader()})

    return http.post("foto",  formData, {
      headers: {  
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + user.accessToken
        },
      onUploadProgress
    });
  }

/*
  getFiles() {
    return http.get("/files", {headers: {  
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer ' + user.accessToken
      }});
  }
  */
}

export default new uploadFilesService();
