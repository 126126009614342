import Vue from 'vue'
import VueRouter from 'vue-router'
import Venda from '../views/Venda.vue'
import Cadastro from '../views/Cadastro.vue'
import Endereco from '../views/Endereco.vue'
import Dependente from '../views/Dependente.vue'
import Contratual from '../views/Contratual.vue'

import Rodape from '../views/itensPage/Rodape.vue'
import Cabecalho from '../views/itensPage/Cabecalho.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Venda',
    component: Venda
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: Cadastro
  },
  {
    path: '/endereco',
    name: 'Endereco',
    component: Endereco
  },
  {
    path: '/dependente',
    name: 'Dependente',
    component: Dependente
  },
  {
    path: '/contratual',
    name: 'Contratual',
    component: Contratual
  },
  {
    path: '/rodape',
    name: 'Rodape',
    component: Rodape
  },
  {
    path: '/cabecalho',
    name: 'Cabecalho',
    component: Cabecalho
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})





export default router
