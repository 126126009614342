<template>
<!--
<div style="height:100%">
  <v-row justify="center" style="margin-top:10%" class="mx-auto">
      <v-col cols="8">
        <v-sheet class="mx-auto" :style="myStyle" > 
          <v-slide-group :style="myStyle, myIcon" v-model="model"  show-arrows active-class="success"> 

            <v-slide-item v-for="n in model":key="n" v-slot="{ active, toggle }">
                <div style="background-color:white; margin-left:0px;width:400px;white-space:normal;" elevation="9"  class="rounded-xl">
                  <div style="width: 90%;margin-left: 5%;margin-top:10px">
                    <div class="text-center" style="font-family: unset;font-style: italic;color: #005587;
                      font-size: unset;padding-top:20spx" :style="corBaseText">
                      <span style="font-size: 34px;font-weight: bold;"> {{n.plano}}</span>
                    </div>
                    <div class="text-center" style="font-family: unset;font-style: italic;color: #005587; font-size: 20px; margin-top:32px">
                      {{n.total_dep}} dependentes | {{n.total_agr}} agregados <br/>
                      {{n.id_caren_menor}} dias de carência até 65 anos <br/>
                      {{n.id_caren_maior_65}} dias de carência para maiores de 65 anos
                    </div>
                    <v-expansion-panels rounded class="mb-1" style="margin-top:4px;  margin-top:16px; font-size: 18px;">
                      <v-expansion-panel
                        v-for="(item,i) in 1"
                        :key="i">
                        <v-expansion-panel-header rounded expand-icon="mdi-menu-down" style="background-color: #ef8932;color: white;">
                          <h3>SERVIÇOS INCLUSOS</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: #ef8932;color: white">{{n.servicos}}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> 

                    <div style="font-family: unset;font-style: italic;color:#005587;margin-top:4px;margin-top:16px; font-size: 20px;">
                    <ul>
                      <li v-for="item in n.items" :key="item.message">
                          {{item.message}}
                         
                        </li>
                    </ul>
                     <div v-if="n.items.length < 5">
                     <br/>
                     </div>
                    </div>
                    <div s> </div>
                    <div class="text-center">
                      <output style="color:#ef8932;font-size:24px;font-weight: bold;font-style: italic;font-family: revert;">R$</output>
                      <output style="color:#ef8932;font-size:72px;font-weight: bold;font-style: italic;font-family: revert;">{{n.valor_i}}</output>
                      <output style="color:#ef8932;font-size:24px;font-weight: bold;font-style: italic;font-family: revert">,{{n.valor_f}}</output>
                      <output style="color:#ef8932;font-size:16px;font-style: italic;font-family: revert">/MÊS</output>
                    </div>

                    <div>
                       <v-dialog
                          transition="dialog-top-transition"
                          max-width="600"
                        >
                          <template v-slot:activator="{ on, attrs }" >
                          <div class="text-center" style=";margin-bottom:10px">
                            <v-btn @click="seleciona_plano(n.valor, n.id, n.plano, n.total_dep, n.total_agr, n.carencia_titular, 
                      n.carencia_dependente, n.carencia_dependente_maior, n.carencia_agregado, n.id_caren_maior_65, n.custo_agregado_maior_65, n.custo_agregado_menor_65)" style="color: white;font-size: 22px;font-weight: 900"
                                v-on="on" class="ma-2" rounded   x-large
                                :style="corSegundaBase"> TENHO INTERESSE 
                            </v-btn>
                          </div>
                          </template>

                          <template v-slot:default="dialog"  >
                                    <v-card>
                                      <v-toolbar
                                      text
                                        :style="corSegundaBase"
                                        dark
                                      > <h3>{{ n.plano }}</h3>
                          </v-toolbar>

                                      <v-card-text>
                                        <div class="text-h8 pa-12"> 
                                          Para facilitar tenha em mãos documentos pessoais:
                                          <ul>
                                            <li>CPF</li>
                                            <li>Documento de Identificação (foto)</li>
                                            <li>Comprovante de Residencia</li>
                                        </ul>


                                        </div>
                                        <div>
                                            Requisitos:<br/>
                                            * Titular com Idade Maior que <strong> 18 Anos </strong> e Menor que <strong> 65 Anos</strong>.
                                        </div> 
                                      </v-card-text>
                                      <v-card-actions class="justify-end">
                                        <v-btn
                                          text
                                          @click="dialog.value = false"
                                        >Cancelar</v-btn>


                                    <v-btn :loading="showload" 
                                   :style="corBase" color="primary"
                                  @click="handleLogin"
                                  
                                >
                                  Iniciar 

                                  <v-icon right dark>mdi-arrow-right</v-icon>
                                </v-btn>


                                      </v-card-actions>
                                    </v-card>
                                  </template>
                                </v-dialog>
                    </div>
                    
                  </div>
                </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>    
      </v-col>
    </v-row>
    <br/>

<v-dialog
      v-model="dialog_error"
      persistent
      max-width="340"
    >

    <v-card>
        <v-card-title class="headline">
         Ops! Houve um problema
        </v-card-title>
        <v-card-text>Servidor com problemas de comunicação, por favor tente novamente mais tarde ou entre em contato. <br/> <br/> Telefone para contato <strong> {{this.telefone_contato}} </strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog_error = false"
          >
            OK
          </v-btn>

        </v-card-actions>
    </v-card>
</v-dialog>             


</div>
-->
<div class="box">
    <div id="title" style="color:white">
      SITE EM MANUTENÇÃO
    </div>
    <img src="https://maestrix.grupoparque.com.br/LOGOSVG4.svg" id="logo">
   
   
  </div>

</template>


<script>
import User from '../models/user'
import custom from '../services/custom'


// @ is an alias to /src
//@click="reserve"
export default {
  created() {

var empresa = ''

if(typeof(NEmpresa.getNome()) !== 'undefined'){
       empresa = NEmpresa.getNome(); 
}


      this.myStyle = custom.myStyle(empresa)
      this.myIcon = custom.myIcon()
      this.corBase = custom.corBase(empresa)
      this.corBaseText = custom.corBaseText(empresa)
      this.corSegundaBase = custom.corSegundaBase(empresa)
      //this.corItensBase = custom.corItensBase(currentUrl)
      this.contrato.empresa = custom.empresa_nome(empresa)
      this.telefone_contato = custom.empresa_tel(empresa)
      this.model = custom.planos(empresa)
      
  },

  data: () => ({
      diaglog: false,
      showload: false,
      loading: false,
      selection: 1,
      dialog_error:false,
      user: new User('', ''),
      myStyle:'',
      myIcon:'',

      corBase: '',
      corBaseText: '',
      corSegundaBase: '',
      corItensBase: '',
      telefone_contato: '',
      //dados_empresa: {nome: '', telefone: ''},
    
    contrato:{
          nome: '',
          cpf: '',
          sexo: '',
          nacionalidade: '',
          estadocivil: '',
          dataCarencia: '',
          vencimento: '',
          dataCarencia: '',
          condicao: '',
          qtdParcelas: '',
          id_plano: '',
          valorContrato: '',
          parcela: '',
          tipo: '',
          nascimento: '',
          rg: '',
          uf_expedidor: '',
          expedidor: '',
          mae: '',
          religiao: '',
          profissao: '',
          pai: '',
          cep: '',
          logradouro: '',
          complemento: '',
          endereco: '',
          n_res: '',
          bairro: '',
          cidade: '',
          uf_end: '',
          pais: '',
        cep_entrega: '',
        repetir_endereco: true,
          complemento_entrega: '',
          endereco_entrega: '',
          n_res_entrega: '',
          bairro_entrega: '',
          cidade_entrega: '',
          uf_end_entrega: '',
          pais_entrega: '',
          telefone_fixo: '',
          celular: '',
          email: '',
          celular2: '',
          empresa: '',
        },
          plano_selecionado: {
          valor: 0, id: 0, descricao: '', qtd_dep : 0, 
          qtd_agr : 0, 
          carencia_titular : 0, 
          carencia_dependente : 0, 
          carencia_dependente_maior : 0, 
          carencia_agregado : 0, 
          carencia_agregado_maior : 0,
          custo_agregado_menor_65: 0,
          custo_agregado_maior_65: 0,
        },

       
       model: []
    }),

    methods: {
    
        handleLogin () {      
          this.showload = true;
          if(this.contrato.empresa === ""){
            this.dialog_error = true;
            this.showload = false;
            return
          }

         this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.showload = false;

              this.$router.push('/cadastro')
            },
            error => {
              this.dialog_error = true;
               this.showload = false;
            },
          )

      
      },

      seleciona_plano(valor, id, descricao, qtd_dep, qtd_agr, carencia_titular, 
      carencia_dependente, carencia_dependente_maior, carencia_agregado, 
      carencia_agregado_maior, custo_maior_65, custo_menor_65){
       
       

        this.$store.commit('setNomePlano', descricao);
        this.$store.commit('setvalorPlano', valor);
        this.$store.commit('setdescricaoPlano', descricao);
        this.$store.commit('setIdPlano', id);
        this.$store.commit('setqtd_depPlano', qtd_dep);
        this.$store.commit('setqtd_agrPlano', qtd_agr);
        this.$store.commit('setcarencia_titularPlano', carencia_titular);
        this.$store.commit('setcarencia_dependentePlano', carencia_dependente);
        this.$store.commit('setcarencia_dependente_maiorPlano', carencia_dependente_maior);
        this.$store.commit('setcarencia_agregadoPlano', carencia_agregado);
        this.$store.commit('setcarencia_agregado_maiorPlano', carencia_agregado_maior);
        this.$store.commit('setcusto_agregado_menor65anos', custo_menor_65);
        this.$store.commit('setcusto_agregado_maior65anos', custo_maior_65);
        this.$store.commit('setListaDependente', [])
        this.$store.commit('setContrato', this.contrato)
        this.$store.commit('setValorEtapa1', false)
        this.$store.commit('setValorEtapa2', false)
        this.$store.commit('setValorEtapa3', false)
        this.dialog = true



      }
    },
  }
</script>

<style>
  li {
  list-style-type: none;
  position:relative;
}


li:before {
  content: '•';
  display: inline-block;
  position: absolute;
  left: -1em;
  color:#ef8932;
}

.v-icon{
  font-size: 64px;
  color: #FFFFFF;
}

.show-arrows{
  border: solid red;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  font-size:50px;
  background:red;
}

.box_elevation {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
  min-height: 200px;
  width: 50vw;
  margin: 100px auto;
  background: white;
  border-radius: 5px;
}

body{
      font-family: 'Titillium Web', sans-serif;
      color: #666666;
      text-align: center;
    }
    .box{
      width: 500px;
      margin: 50px auto;
    }
    #title {
      font-size: 30px;
      margin-top: 25px;
    }
    #descricao{
      font-size:20px;
      margin: 20px auto;
    }
    #logo {
      margin-top: 25px;
      max-width: 365px;
      height: auto;
    }
    #logo-task {
      width: 75px;
    }

</style>
