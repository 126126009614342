import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './store/auth.module'

Vue.use(Vuex)

   const state = { 
        contrato_selecionado: {
            nome: '',
            cpf: '',
            sexo: '',
            nacionalidade: '',
            estadocivil: '',
            data_contrato: '',
            vencimento: '',
            dataCarencia: '',
            condicao: '',
            qtdParcelas: '',
            id_plano: '',
            descricao_plano: '',
            valorContrato: '',
            parcela: '',
            tipo: '',
            nascimento: '',
            rg: '',
            uf_expedidor: '',
            expedidor: '',
            mae: '',
            religiao: '',
            profissao: '',
            pai: '',
            cep: '',
            logradouro: '',
            complemento: '',
            endereco: '',
            n_res: '',
            bairro: '',
            cidade: '',
            uf_end: '',
            pais: '',
            cep_entrega: '',
            repetir_endereco: true,        
            complemento_entrega: '',
            endereco_entrega: '',
            n_res_entrega: '',
            bairro_entrega: '',
            cidade_entrega: '',
            uf_end_entrega: '',
            pais_entrega: '',
            telefone_fixo: '',
            celular: '',
            email: '',
            celular2: '',
            id: 0,
            empresa: '',
            
        },
        plano_selecionado: {
            valor: 0.0, 
            id: 0,
            descricao: '', 
            qtd_dep : 0, 
            qtd_agr : 0, 
            carencia_titular : 0, 
            carencia_dependente : 0, 
            carencia_dependente_maior : 0, 
            carencia_agregado : 0, 
            carencia_agregado_maior : 0,
            custo_ate_65_anos: 0,
            custo_maior_65_anos:0,

        },
        valor_total_agregado: 0,
        empresa: '',
        dependentes: [],
        files: [],
        etapa1: false,
        etapa2: false,
        etapa3:false,
    }

    const mutations = {
            setEmpresa(state, payload){
                state.empresa = payload
            },

            setValorEtapa1(state, payload){
                state.etapa1 = payload
            },

            setValorEtapa2(state, payload){
                state.etapa2 = payload
            },

            setValorEtapa3(state, payload){
                state.etapa3 = payload
            },

            setValorTotalDependente(state, payload){
                state.valor_total_agregado = payload
            },
    
            setContrato(state, payload){
            state.contrato_selecionado = payload
            },

            setIdContrato(state, payload){
                state.contrato_selecionado.id = payload
            },

            setDiaVencimento(state, payload){
                state.contrato_selecionado.vencimento = payload
            },
            setParcela(state, payload){
                state.contrato_selecionado.parcela = payload
            },

            setListaDependente(state, payload){
                state.dependentes = (payload);
        
            },

            setDependente(state, payload){
                state.dependentes.concat(payload);
        
            },

            setFiles(state, payload){
                state.files.concat(payload);
        
            },

            removerDependente(state, payload){            
                let deps = state.dependentes.filter(d => d.nome != payload)
                state.dependentes = deps;

            },
        
            setNomePlano(state, payload){
                state.plano_selecionado.descricao = payload
            },

            setvalorPlano(state, payload){
                state.plano_selecionado.valor = payload
            },

            setdescricaoPlano(state, payload){
                state.plano_selecionado.descricao = payload
            },

            setIdPlano(state, payload){
                state.plano_selecionado.id = payload
            },

            setqtd_depPlano(state, payload){
                state.plano_selecionado.qtd_dep = payload
            },

            setqtd_agrPlano(state, payload){
                state.plano_selecionado.qtd_agr = payload
            },

            setcarencia_titularPlano(state, payload){
                state.plano_selecionado.carencia_titular = payload
            },

            setcarencia_dependentePlano(state, payload){
                state.plano_selecionado.carencia_dependente = payload
            },

            setcarencia_dependente_maiorPlano(state, payload){
                state.plano_selecionado.carencia_dependente_maior = payload
            },

            setcarencia_agregadoPlano(state, payload){
                state.plano_selecionado.carencia_agregado = payload
            },

            setcarencia_agregado_maiorPlano(state, payload){
                state.plano_selecionado.carencia_agregado_maior = payload
            },
            setcusto_agregado_maior65anos(state, payload){
                state.plano_selecionado.custo_maior_65_anos = payload
            },
            setcusto_agregado_menor65anos(state, payload){
                state.plano_selecionado.custo_ate_65_anos = payload
            }

    }

    const getters = {
        plano_selecionado: state => state.plano_selecionado,
        contrato_selecionado: state => state.contrato_selecionado,
        valor_total_agregado: state => state.valor_total_agregado,
        listadependentes: state => state.dependentes,
        listafiles: state => state.files,
        etapa1: state => state.etapa1,
        etapa2: state => state.etapa2,
        etapa3: state => state.etapa3,
        empresa: state => state.empresa
        //userName: state => state.plano_selecionado.descricao
    }



    export default new Vuex.Store({
        state,
        mutations,
        getters,
        modules: {
          auth,
        },
        actions: {
        },
      })
