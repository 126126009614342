 const logo = "";

 function myIcon() {
    return { 

    }
 }

 function myStyle(empresa) {
       
    if(empresa === "Memorial Parque Maceió"){
        return {
          backgroundColor: "#2E8B57",
          justifyContent:"center",
          marginLeft:"4%",
          height:"100%",
          }  
      }

      if(empresa === "Parque das Flores"){
        return {
          backgroundColor: "#5E91AE",
          justifyContent:"center",
          marginLeft:"4%",
          height:"100%",
          }   
      }

      if(empresa === "Previda"){
        return {
          backgroundColor: "#005486",
          justifyContent:"center",
          marginLeft:"4%",
          height:"100%",
          }   
      }


 }

 function corBase(empresa) {
      

  if(empresa === "Memorial Parque Maceió"){
    return  {
          backgroundColor: "#2E8B57",
          }  
      }

      if(empresa === "Parque das Flores"){
        return  {
        backgroundColor: "#5E91AE",
      }  
    }
    if(empresa === "Previda"){
      return {
            backgroundColor: "#005486",

        }
      } else {

        return {
          backgroundColor: "#424242",

        }
      }

 }

 function corSegundaBase(empresa) {
    
    

  if(empresa === "Memorial Parque Maceió"){
    return  {
        backgroundColor: "#2E8B57",
        }  
    }

    if(empresa === "Parque das Flores"){
      return  {
      backgroundColor: "#5E91AE",
    }  
  }
  if(empresa === "Previda"){
    return {
          backgroundColor: "#005486",

      }
    }
}

function corItensBase(empresa) {
     
  if(empresa === "Memorial Parque Maceió"){
    return  {
        backgroundColor: "#ef8932",
        color: white
        }  
    }

      if(empresa === "Parque das Flores"){
      return  {
      backgroundColor: "#5E91AE",
      color: white
    }  
  }
  if(empresa === "Previda"){
    return {
          backgroundColor: "#ef8932",
          color: white

      }
    }

}

 function corBaseText(empresa) {
     

  if(empresa === "Memorial Parque Maceió"){
    return  {
        color: "#2E8B57",
        }  
    }

      if(empresa === "Parque das Flores"){
      return  {
        color: "#5E91AE",
        }  
    }

    if(empresa === "Previda"){
      return {
          color: "#005486",
        }
      }



}

function empresa_tile(empresa) {

  if(empresa === "Memorial Parque Maceió"){
    return  "Memorial Parque Maceió Parque Maceió - Venda Online"}
  
      if(empresa === "Parque das Flores"){
      return  "Parque das Flores - Venda Online"}

      if(empresa === "Previda"){
        return  "Previda é Mais Seguro - Venda Online"}

        if(empresa === ""){
          return  "Grupo Parque das Flores"}
}

function empresa_nome(empresa) {

  if(empresa === "Memorial Parque Maceió"){
    return  "Memorial Parque Maceió Parque Maceió"}
  
      if(empresa === "Parque das Flores"){
      return  "Parque das Flores"}

      if(empresa === "Previda"){
        return  "Previda"}
        
        if(empresa === ""){
          return  "Grupo Parque das Flores"}
}

function empresa_tel(empresa) {

  if(empresa === "Memorial Parque Maceió"){
    return  "(82) 3353-4202"}
  
      if(empresa === "Parque das Flores"){
      return  "(82) 3338-9844"}

      if(empresa === "Previda"){
        return  "0800 009 1700"}
}

function planos(empresa){
  if(empresa == "Memorial Parque Maceió"){
    return [
     
      {
        id: 1, plano: 'PLANO PROTEÇÃO AMIGO 10 VIDAS',
        items: [
          { message: 'Sorteio Semanal R$ 10.000,00' },{ message: 'Seguro de Vida R$ 3.000,00' },{ message: 'Seguro por Morte Acidental R$ 3.000,00' },
          { message: '12 Mensalidades' },
        ],total_dep: 9, total_agr: 2, id_caren_maior_65: 180, id_caren_menor: 90,carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 90.00, valor_i: '90',
        valor_f: '00', custo_menor_65: 13.50, descricao_valor: 'R$ 90,00', custo_agregado_menor_65:  13.50, custo_agregado_maior_65: 27.00,
        servicos: 'Urna mortuária, Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Floricultura, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, ARRANJO 01'
      },

      {
        id: 2, plano: 'PLANO PROTEÇÃO AFETO 10 VIDAS',
        items: [
        { message: 'Sorteio Semanal R$ 10.000,00' },{ message: 'Seguro de Vida R$ 3.000,00' },{ message: 'Seguro por Morte Acidental R$ 3.000,00' },
          { message: '12 Mensalidades' },
        ],
      total_dep: 9, total_agr: 2, id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, carencia_dependente : 90, 
      carencia_dependente_maior : 180, valor: 105.00, valor_i: '105', valor_f: '00', custo_menor_65: 15.75, descricao_valor: 'R$ 105.00',
      custo_agregado_menor_65: 15.75, custo_agregado_maior_65: 31.50,
        servicos: 'Urna mortuária, Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Floricultura, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, ARRANJO 01'
      },


      {
        id: 3, plano: 'PLANO PROTEÇÃO ABRAÇO 10 VIDAS',
        items: [
        { message: 'Sorteio Semanal R$ 20.000,00' },{ message: 'Seguro de Vida R$ 4.000,00' },{ message: 'Seguro por Morte Acidental R$ 4.000,00' },
          { message: '12 Mensalidades' },
        ],
        total_dep: 9, total_agr: 2,  id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 120.00, valor_i: '120',
        valor_f: '00', custo_agregado_menor_65: 18.00, custo_agregado_maior_65: 36.00, descricao_valor: 'R$ 120,90',
        servicos: 'Urna mortuária, Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Floricultura, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, ARRANJO 01'
      },

      
    ]
  }

  if(empresa == "Parque das Flores"){
    return [
      {
        id: 1, plano: 'PLANO PARQUE TOTAL 5 VIDAS',
        items: [
          { message: 'Sorteio Semanal R$ 15.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },
        ],total_dep: 4, total_agr: 2, id_caren_maior_65: 180, id_caren_menor: 90,carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 64.00, valor_i: '285',
        valor_f: '50', custo_menor_65: 42.82, descricao_valor: 'R$ 285,50', custo_agregado_menor_65: 42.82, custo_agregado_maior_65: 85.65,
        servicos: 'Urna mortuária (R$ 4.300,00), Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Crematório, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, Tanatopraxia'
      },

      {
        id: 2, plano: 'PLANO FAMÍLIA PARQUE 5',
        items: [
          { message: 'Sorteio Semanal R$ 15.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },

        ],
      total_dep: 4, total_agr: 2, id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, carencia_dependente : 90, 
      carencia_dependente_maior : 180, valor: 111.20, valor_i: '111', valor_f: '20', custo_menor_65: 14.74, descricao_valor: 'R$ 111,20',
      custo_agregado_menor_65: 14.74, custo_agregado_maior_65: 29.49,
        servicos: 'Urna mortuária (R$ 4.300,00), Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Crematório, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, Tanatopraxia'
      },


      {
        id: 3, plano: 'PLANO FAMÍLIA PREMIUM 5',
        items: [
          { message: 'Sorteio Semanal R$ 15.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },

        ],
        total_dep: 4, total_agr: 2,  id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 140.80, valor_i: '140',
        valor_f: '80', custo_agregado_menor_65: 18.69, custo_agregado_maior_65: 37.38, descricao_valor: 'R$ 140,80',
        servicos: 'Urna mortuária (R$ 7.700,00), Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Crematório, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, Tanatopraxia'
      },
      {
        id: 4, plano: 'PLANO PARQUE TOTAL 10 VIDAS',
        items: [
          { message: 'Sorteio Semanal R$ 30.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },

        ],total_dep: 9, total_agr: 3, id_caren_maior_65: 180, id_caren_menor: 90,carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 381.10, valor_i: '381',
        valor_f: '10', custo_menor_65: 50.58, descricao_valor: 'R$ 381,10', custo_agregado_menor_65: 50.58, custo_agregado_maior_65: 101.16,
        servicos: 'Urna mortuária (R$ 4.300,00), Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Crematório, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, Tanatopraxia'
      },

      {
        id: 5, plano: 'PLANO FAMÍLIA PARQUE 10',
        items: [
          { message: 'Sorteio Semanal R$ 30.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },
        ],
      total_dep: 9, total_agr: 3, id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, carencia_dependente : 90, 
      carencia_dependente_maior : 180, valor: 148.50, valor_i: '148', valor_f: '50', custo_menor_65: 22.27, descricao_valor: 'R$ 148,50',
      custo_agregado_menor_65: 22.27, custo_agregado_maior_65: 44.55,
        servicos: 'Urna mortuária (R$ 4.300,00), Ornamentação de urna, Higiênizacão do corpo, Translado de urna funerária em carro especial, Serviço Crematório, Serviço Funeral, Serviço Cemiterial, Vestimenta Social, Transporte familiar em carro confortável, Tanatopraxia'
      },


      {
        id: 6, plano: 'PLANO FAMÍLIA PREMIUM 10',
        items: [
          { message: 'Sorteio Semanal R$ 30.000,00' }, { message: 'Pagamento 12 Mensalidades (Falecimento Titular)' },
        ],
        total_dep: 9, total_agr: 3,  id_caren_maior_65: 180, id_caren_menor: 90, carencia_titular : 90, 
        carencia_dependente : 90, carencia_dependente_maior : 180, valor: 445.70, valor_i: '445',
        valor_f: '70', custo_agregado_menor_65: 59.14, custo_agregado_maior_65: 118.29, descricao_valor: 'R$ 445,70',
        servicos: 'Urna mortuária (R$ 7.700,00), Ornamentação de urna, Higiênizacão do corpo, Translado, Transporte Familiar, Montagem de parametros, Vestimenta Social, Capela central de velorios, Taxa de sepultamento cemitério público, Pagamento da taxa de Missa Comunitária de 7 dia e Kit café'
      },

      
      
    ]
  } 
  if(empresa === ""){
    return  []}
}



 export default { 
    myStyle,
    myIcon,
    corBase,
    corBaseText,
    corSegundaBase,
    corItensBase,
    empresa_nome,
    empresa_tel,
    planos,
    empresa_tile
    
}

