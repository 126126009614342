<template>
<v-app>
  <v-main :style="corBase">
         <router-view></router-view>
  </v-main>
</v-app>

</template>

<script>
import custom from './services/custom'

export default {
 
   created() {

      var empresa = ''

      //var currentUrl = location.origin;
      //this.corBase = custom.corBase(empresa)
      //this.corBase =  'backgroundColor: "#424242"', //custom.corBase(empresa)
      document.title = custom.empresa_tile(empresa)
  },

 data: () => ({ 
        myStyle : '',
        corBase: 'backgroundColor: #424242',
        //title: "Grupo Parque das Flores - Venda Online",

 }),
    computed:{   
      isAuthenticated() {
        return this.$store.state.isTopBar
      },
    },

 
  };
</script>
