import axios from 'axios'
import config from './config'

const API_URL =  config.url + 'auth/' 

class AuthService {
  login () {
    return axios
      .post(API_URL + 'signin', {
        username: 'mod',
        password: '123456',
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
  }

  register (user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
    })
  }
}

export default new AuthService()
