<template>
  <div>

    <v-container fluid style="width:90%; margin-top:40px;background-color:#f5f5f5;">

<v-stepper non-linear v-model="e1" style="background-color:#efefef;">
      <v-stepper-header>
        <v-stepper-step step="1">
          Cadastro
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">
          Endereço
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Dependentes
        </v-stepper-step>

           <v-divider></v-divider>

        <v-stepper-step step="4">
          Conclusão
        </v-stepper-step>


      </v-stepper-header>
    </v-stepper>





    <v-form ref="form" style="margin-top:20px">
      <form @submit.prevent="salvar">

        <v-banner style="margin-top:20px;" single-line>
     
          <template v-slot:actions>
            <v-btn @click="voltar" color="warning"  v-if="dados_pagamento.venda_aprovado == false"> 
                 <v-icon style="margin-right:8px;" right dark>mdi-arrow-left</v-icon>   Voltar 
            </v-btn>
        

          </template>

        </v-banner>
    <br/>
    

    <v-row align="center">
      <v-col class="d-flex" style="height:80px" cols="12" sm="12">
          <v-select v-model="dia_vencimento"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="vencimentos" dense label="Escolha o dia de vencimento do carnê" outlined></v-select>
        </v-col> 

    </v-row>
    <v-row align="center">
      <v-col class="d-flex" style="height:80px" cols="12" sm="12">

              <v-text-field
                value="" v-model="nome_plano"
                label="Plano Selecionado"
                outlined
                dense
                readonly
              ></v-text-field>

    </v-col> 
    </v-row>
    <v-row align="center">

         <v-col class="d-flex" style="height:60px" cols="12" sm="12">

              <v-text-field
                value="" v-model="valor_plano"
                label="Valor do Plano"
                outlined
                dense
                readonly
                prefix="R$"
              ></v-text-field>

    </v-col> 
    </v-row>
    <v-row align="center">

         <v-col class="d-flex" style="height:60px" cols="12" sm="12">

              <v-text-field
                value="" v-model="valor_adicional"
                label="+ Adicional Agregado"
                outlined
                dense
                readonly
                prefix="R$"
              ></v-text-field>

    </v-col> 
    </v-row>
    <v-row align="center">

 <v-col class="d-flex" style="height:60px" cols="12" sm="6">

              <v-text-field style="font-size:20px"
                value="" v-model="valor_mensalidade"
                label=" = Valor Mensalidade"
                outlined
                color="red"
                readonly
                prefix="R$"
              ></v-text-field>

    </v-col> 


    <v-col class="d-flex" style="height:60px;font-color:red;color:green" cols="12" sm="6">

              <v-text-field style="font-size:20px"
                value="" v-model="valor_adesao"
                label="Valor Adesão"
                outlined         
                color="success"
                readonly
                prefix="R$"
              ></v-text-field>

    </v-col> 



    </v-row>

    <v-banner style="margin-top:20px;">
    <template v-slot:actions>
        <v-btn  color="secondary" @click="dialog_ipt = true"> 
          ENVIAR DOCUMENTOS
        <v-icon right dark>mdi-upload</v-icon>
        </v-btn>
    </template>
    </v-banner>

    <v-banner style="margin-top:20px;">
    <template v-slot:actions>
      <v-btn :loading="showload" color="success" v-if="dados_pagamento.venda_aprovado == false"
      class="mr-4" @click="prosseguir">EFETUAR PAGAMENTO DA ADESÃO
            <v-icon right dark>mdi-credit-card</v-icon>
      </v-btn>
    <v-btn :loading="showload" color="success" v-if="dados_pagamento.venda_aprovado"
      class="mr-4"
      @click="concluir">SAIR
            <v-icon right dark>mdi-close</v-icon>
    </v-btn>

<v-dialog v-model="dialog" persistent max-width="600px">
    
      <v-card>
         <v-toolbar color="success" dark> <span class="headline">Pagamento com Cartão de Crédito</span></v-toolbar>
        <br/>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" style="margin-top:20px"> 
            <v-row>
              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field  v-model="dados_pagamento.cpf_pagador" v-mask="'###.###.###-##'"  Required  label="CPF do Pagador*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="dados_pagamento.nome_cartao" label="Nome impresso no cartão*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="dados_pagamento.numero_cartao" label="Número*" v-mask="'#### #### #### ####'"  :rules="[v => !!v || 'Campo Obrigatório']"  v-on:change="ler_bandeira"    outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field value="" v-model="dados_pagamento.bandeira" label="Bandeira" :rules="[v => !!v || 'Campo Obrigatório']"  outlined dense readonly></v-text-field>
              </v-col> 

             <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                  <v-select v-model="dados_pagamento.mes_vencimento"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="meses" dense label="Mês*" outlined></v-select>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                <v-text-field v-model="dados_pagamento.ano_vencimento" label="Ano*"   minlength="4" v-mask="'####'" :rules="[rules_ano_cartao.required, rules_ano_cartao.counter]"  outlined dense></v-text-field>
              </v-col>

               <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                <v-text-field v-model="dados_pagamento.cvv" label="CVV*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn color="success" outlined @click="clearCard">
            Fechar
          </v-btn>
          <v-btn :loading="showload"  color="success" @click="paga">
           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog v-model="dialog_ipt" persistent max-width="600px">

      <v-card>
        <v-card-title>
          <span class="headline">Documentos</span>
        </v-card-title>
        <v-card-text>
          <v-container> 
                Enviar documentos de cadastro: RG, Comprovante de Residência e CPF
                    <v-row no-gutters justify="center" align="center">
                    <v-col cols="8">
                      <v-file-input color="blue"
                        show-size
                        label="Selecione o arquivo"       
                        @change="selectFile" 
                      ></v-file-input>
                    </v-col>

                    <v-col cols="4" class="pl-2">
                      <v-btn color="success" dark small @click="upload" :loading="showload_img">
                        Enviar
                        <v-icon right dark>mdi-cloud-upload</v-icon>
                      </v-btn>
                    </v-col>

                  </v-row>
                  DOCUMENTOS OBRIGATÓRIOS PARA ENVIO: <br/>
•	Documento válido de identificação do titular do contrato <br/>
•	Documento de comprovação de endereço de até 3 meses de emitido <br/>

                  * Limite de 4 arquivos <br/> <br/>
                 <strong> Arquivos enviados com sucesso: </strong> <br/>


                          <ul id="example-1" style="font-size:20px;color:green;">
                            <li v-for="item in listafiles" v-bind:key>
                              <v-chip class="ma-2" color="green" outlined label> {{ item.no }} - ARQUIVO ENVIADO COM SUCESSO</v-chip>
                            </li>
                          </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_ipt = false">
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-banner>

<v-dialog v-model="dialog_infor"
      width="500">

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Alerta
        </v-card-title>

        <v-card-text>
          Você não enviou documentos necessário para validação do seu contrato. A sua carência só iniciará após envio de todos os documentos obrigatórios para a conferência das informações preenchidas.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_infor = false"
          >
              OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<v-dialog 
        transition="dialog-bottom-transition"
        max-width="600"  v-model="dialog_cielo">

        <template>
          <v-card>
          <v-toolbar color="success" dark> <span class="headline">Informação</span></v-toolbar>

            <v-card-text v-if="dados_pagamento.venda_aprovado" >
              <div id="pnl">

                <br/>
                   <h3> <v-icon large color="green darken-2">mdi-check</v-icon> Parabéns seu Pagamento foi Aprovado</h3> <br/>
                    <h3> Seu cadastro seguirá para o setor de análise, breve entraremos em contato.</h3> <br/>
                    <v-row>
                   <h4>Você escolheu o {{plano_selecionado.descricao}}</h4>
                   </v-row>                    
                   <v-row>
                    <h4>Valor: R$  {{dados_pagamento.valor}}</h4>
                   </v-row>
                  <v-row>
                    <h4>Data: {{ new Date() | dateFormat('DD/MM/YYYY') }} </h4>
                  </v-row>
                  <v-row>
                    <h4>Nº da Transação: {{dados_pagamento.cod_transacao}}</h4><br/>
                  </v-row> 
                  <v-row>
                    <h4>Mensagem: {{dados_pagamento.mensagem_cielo}}</h4>
                  </v-row>  
              </div>  
            </v-card-text>


            <v-card-actions class="justify-end" v-if="dados_pagamento.venda_aprovado">
               <v-btn
                outlined
                color="orange"
                @click="print" :loading="showload_print"
              >Imprimir <v-icon right dark> mdi-printer
      </v-icon></v-btn>
              <v-btn
                outlined
                color="success" @click="concluir"
              >Finalizar e Sair<v-icon right dark> mdi-check
      </v-icon></v-btn>
            </v-card-actions>



            <v-card-text v-if="dados_pagamento.venda_aprovado == false">
              <br/>
                  <h3> <v-icon large color="red darken-2">mdi-close</v-icon> Pagamento não foi concluido, verifique os dados do cartão e tente novamente.</h3> <br/>
                    <h4>Mensagem: {{dados_pagamento.mensagem_cielo}}</h4>
            </v-card-text>
           

               <v-card-actions class="justify-end" v-if="dados_pagamento.venda_aprovado == false">
              <v-btn
                outlined
                color="success" @click="dialog_cielo = false"
              >OK<v-icon right dark> mdi-check
      </v-icon></v-btn>
            </v-card-actions>



          </v-card>
        </template>
      </v-dialog>


  
       </form>
    </v-form>

    </v-container>

  </div> 

</template>

<script>

import Contrato from '../services/contrato'
import Pagamento from '../services/pagamento'

import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Cabecalho from '../views/itensPage/Cabecalho';
//import FormDataPost from '..services/upload';
import UploadService from "../services/uploadFilesService";

//...

  export default {

  name: "upload-files",
/*
  mounted() {
    UploadService.getFiles().then(response => {
      this.fileInfos = response.data;
    });
  },
*/
  components: {Cabecalho },
   

      data () {

        return {
          currentFile: undefined,
          progress: 0,
          message: "",
          showload_print : false,
          fileInfos: [],

          globalOptions: {
            name: '_blank',
            specs: [
              'fullscreen=yes',
              'titlebar=yes',
              'scrollbars=yes'
            ],
            styles: [
              'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
              'https://unpkg.com/kidlat-css/css/kidlat.css'
            ]
          },
          localLandScapeOptions: {
            styles: [
              'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
              'https://unpkg.com/kidlat-css/css/kidlat.css',
              './landscape.css'
            ]
          }
        }
      },

  created() {
    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }

      if(this.etapa3 == false){
      this.$router.push('/dependente')
    }


    this.nome_plano = this.plano_selecionado.descricao
    this.valor_plano = this.plano_selecionado.valor
    this.valor_adicional = this.valor_total_agregado
    this.valor_mensalidade = (this.plano_selecionado.valor + this.valor_total_agregado).toFixed(2);
    this.valor_adesao = (this.plano_selecionado.valor + this.valor_total_agregado).toFixed(2);

 },

  computed:{
    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado' , 'valor_total_agregado', 'listafiles', 'etapa3', 'listadependentes'
        ]),

  
  },
  
 
 data: () => ({ 

        msg_up: '',
        fileInfos: [],
        showload_img: false,
        dialog_infor: false,
        dialog_ipt: false,
        dialog: false,
        date_ft: new Date(),
        dialog_cielo: false,
        showload: false,
        dia_vencimento: '',
        snackbar: false,
        text_snack: '',
        e1 : 4,
        nome_plano: '',
        valor_plano: '',
        valor_adicional: '',
        valor_mensalidade: '',
        valor_adesao: '',
        
        dados_pagamento:{
              cpf_pagador: '',
              nome_cartao: '',
              numero_cartao: '',
              mes_vencimento: '',
              ano_vencimento: '',
              valor: 0,
              cod_transacao: 0,
              tipo: 'C',
              data: '', 
              cvv: '',
              contrato: 0,
	            mensagem_cielo: '',
              venda_aprovado: false,
              bandeira: '',
              
        },

    rules: [
        value => !!value || 'Required.',
       // value => (value && value.length >= 3) || 'Min 3 characters',
       //required: value => !!value || 'Required.',
       //counter: value => value.length < 5 || 'Max 20 characters',
      ],



    rules_ano_cartao: {
          required: value => !!value || 'Campo Obrigatório',
          counter: value => value.length > 3 || '4 digitos',
      },

    select: null,
    vencimentos: [
        '01',
        '05',
        '15',
        '20',
        '25'

      ], 

      meses: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'

      ], 

    }),


  methods:{


      dataAtualFormatada(){
        var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
        return diaF+"/"+mesF+"/"+anoF;
    },
    voltar(){
           this.$router.push('dependente')
      },

    print() {
          this.showload_print = true;
          Impressao.getImpressao(this.plano_selecionado.descricao, this.dados_pagamento.valor, 
          this.dados_pagamento.cod_transacao, this.dados_pagamento.mensagem_cielo);
          this.showload_print = false;

        },


    clearCard () {  

        this.$refs.form_card.reset();
        this.dialog = false;
        /*
        this.cpf_pagador = '',
        this.nome_cartao = '',
        this.numero_cartao = '',
        this.mes_vencimento = '',
        this.ano_vencimento = '',
        this.valor = 0,
        this.cod_transacao = 0,
        this.tipo = 'C',
        this.data = '',
        this.cvv = '',
        this.id_contrato = 0,
	      this.mensagem_cielo = '',
        this.venda_aprovado = false,
        this.bandeira = ''
        */
      },
      
      clear () {       
        this.$refs.form.reset();
      },
      

      async prosseguir(){
        if(this.$refs.form.validate()){
  
            this.showload = true;
            this.$store.commit('setParcela', this.valor_total_agregado + this.plano_selecionado.valor)
            this.$store.commit('setDiaVencimento', this.dia_vencimento)
            this.contrato_selecionado.nome_plano = this.plano_selecionado.descricao
            Contrato.salvar(this.contrato_selecionado).then(response => {
            
            if (this.listafiles.length<2) {
                      this.dialog_infor = true
            }else{
            }

                      this.dialog = true

            }).catch(e =>{

            }).finally(() => {
              this.showload = false;
              this.$router.push('contratual')
          });
        }
      },

      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
      },

      async paga(){
                //alert(this.contrato_selecionado.nome)
        this.dados_pagamento.contrato = this.contrato_selecionado.id
        this.dados_pagamento.valor = this.valor_total_agregado + this.plano_selecionado.valor
        
      

        if(this.$refs.form.validate() && this.$refs.form_card.validate()){
            this.showload = true;

            Pagamento.salvar(this.dados_pagamento).then(response => {
                    
                    this.dados_pagamento.mensagem_cielo = response.data.mensagem_cielo;
                    this.dados_pagamento.venda_aprovado = response.data.venda_aprovado;
                    this.dados_pagamento.cod_transacao = response.data.cod_transacao;


            }).catch(e =>{
                    this.clearCard();
                    this.dados_pagamento.mensagem_cielo = 'Falha na Transação, verifique os dados do seu cartão';
                    this.dados_pagamento.venda_aprovado = false;

            }).finally(() => {
              this.showload = false;
              this.clearCard();
              this.dialog = false;
              this.dialog_cielo = true;   
              this.$store.commit('setIdPlano', '')

           

          });
        }
      },


    


    async ler_bandeira(){
    
      this.showload = true;
      Pagamento.ler_bandeira(this.dados_pagamento).then(response => {
                 this.dados_pagamento.bandeira = response.data.bandeira;
              }).catch(e =>{
                            //console.log(e)

             }).finally(() => {
                this.showload = false;
            });
        
      },


      concluir(){
            this.$store.commit('setIdPlano', '')
            this.$router.push('/')
            Impressao.getFechar(this.plano_selecionado.descricao, this.dados_pagamento.valor, 
          this.dados_pagamento.cod_transacao, this.dados_pagamento.mensagem_cielo);

      },
      upload() {


        if (!this.currentFile) {
          return;
        }

        if (this.listafiles.length>3) {
          return;
        }

        

        this.showload_img = true;
        this.message = "";

        UploadService.upload(this.contrato_selecionado.id, this.currentFile, (event) => {
          //this.progress = Math.round((100 * event.loaded) / event.total);
                                  

        })
          .then((file) => {

               var item = { no: this.currentFile.name}

                this.listafiles.push(item);
                this.$store.commit('setFiles', item)

            this.showload_img = false;
             this.fileInfos.concat = 'teste'//this.currentFile.name;

          })
          .catch(() => {
            this.progress = 0;
            this.showload_img = false;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      },

       


  }
  }

</script>
<style>

.li:before{

  color: green;
}
</style>